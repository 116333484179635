<template>
  <article
    class="flex flex-col items-start justify-between px-4 py-4 bg-white rounded-lg shadow-md"
  >
    <div class="relative w-full">
      <a :href="`/blog/${article.slug}`">
        <img
          :src="article.thumbnail"
          :alt="article.title"
          class="cursor-pointer aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
        />
      </a>
    </div>
    <div class="max-w-xl">
      <div class="flex items-center mt-6 text-xs">
        <i18n-d
          class="text-gray-500"
          tag="p"
          scope="global"
          :value="new Date(article.date)"
          format="short"
        />
      </div>
      <div class="relative group">
        <h2
          class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600"
        >
          <a
            :href="`/blog/${article.slug}`"
            class="hover:underline hover:underline-offset-2"
          >
            <span class="absolute inset-0" />
            {{ article.title }}
          </a>
        </h2>
        <p class="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">
          {{ article.description }}
        </p>
      </div>
    </div>
    <div class="relative flex items-center mt-3 gap-x-4">
      <div class="text-sm leading-6">
        <p class="font-semibold text-gray-900">
          <span class="absolute inset-0" />
          {{ article.author }}
        </p>
      </div>
    </div>
    <VButton
      :variant="Variant.tertiary"
      :size="Size.sm"
      class="mt-6 w-36"
      @click="handleReadArticle(article.slug)"
    >
      {{ $t('blogSession.readArticle') }}
    </VButton>
  </article>
</template>

<script setup lang="ts">
import { Variant, Size } from '@/types/enums'
import type { ArticleContentItem } from '@/types/interfaces'

defineProps<{
  article: ArticleContentItem
}>()

function handleReadArticle(slug: string) {
  useRouter().push(`/blog/${slug}`)
}
</script>
