<template>
  <NuxtLayout name="public">
    <div class="px-4 py-8 m-auto max-w-7xl md:px-6 lg:px-8">
      <div class="mt-6 text-center">
        <h1 class="text-4xl font-bold">
          Inside <span class="text-cred">Colonia</span>
        </h1>
      </div>
      <div
        class="grid max-w-2xl grid-cols-1 mx-auto mt-12 mb-16 gap-x-10 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3"
      >
        <template v-for="article in articles" :key="article._path">
          <BlogCard :article="article" />
        </template>
      </div>
      <VPagination
        :page-size="PAGE_SIZE"
        :current-page="currentPage"
        :total-count="articleCount"
        @update:current-page="handlePageChange"
      />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { ArticleContentItem } from '@/types/interfaces'

const PAGE_SIZE = 9

const route = useRoute()
const router = useRouter()
const currentPage = computed(() => Number(route.query.page) || 1)
const articleCount = await queryContent('articles').count()

const { data: articles } = await useAsyncData(
  'articles',
  () =>
    queryContent('articles')
      .sort({ date: -1 })
      .limit(PAGE_SIZE)
      .skip(PAGE_SIZE * (currentPage.value - 1))
      .find() as Promise<ArticleContentItem[]>,
  {
    watch: [currentPage],
  }
)

function handlePageChange(newPage: number) {
  router.push({ query: { page: newPage } })
}
</script>
